import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    props: true,
    meta: {
      stub: {
        fr: '',
        en: '',
      },
    },
  },
  {
    path: '/web',
    name: 'Web',
    component: () => import('@/views/Home.vue'),
    props: true,
    meta: {
      stub: {
        en: '/?g=web-en',
        fr: '/?g=web-fr',
      },
    },
  },
  { path: '*', name: 'Error', component: () => import('@/views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
